import {
  createSignal,
  Show,
  type Component,
  Switch,
  Match,
  onCleanup,
  onMount,
  createEffect,
} from "solid-js";
import { useErrorContext, useSessionContext } from "~/utils/contexts";
import {
  A,
  useNavigate,
  useSearchParams,
  useAction,
  useSubmission,
  revalidate,
} from "@solidjs/router";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import CDialog from "@corvu/dialog";
import { exclamationCircle, xMark } from "solid-heroicons/outline";
import Button from "../Button";
import { TextFieldInput } from "../inputs";
import { Icon } from "solid-heroicons";
import {
  checkCircle,
  user,
  handRaised,
  pauseCircle,
} from "solid-heroicons/outline";
import { eyeSlash } from "solid-heroicons/solid";
import { Loader } from "../utility";
import { loginAction } from "~/services/account";
import { Motion, Presence } from "solid-motionone";
import { APIError } from "~/services/roma-api/errors";
import { checkError } from "~/services/roma-api/checkError";
import { fetchAPI2 } from "~/services/roma-api/fetchAPI2";
import type { AccountStatus } from "~/services/roma-api/account/types";

import { getFavourites } from "~/services/favourites";

import "./signInModal.css";
import { revalidateOnSession } from "~/services/session";
import { properCase } from "~/utils/helpers";

import * as Sentry from "@sentry/browser";

type SignInModalProps = {
  open: () => boolean;
  onClose: () => void;
};

type CloseCompProps = {
  closeFn: () => void;
  ms: number;
  class?: string;
};
type IntervalType = ReturnType<typeof setInterval>;

const CloseComp: Component<CloseCompProps> = (props) => {
  const [timeLeftMs, setTimeLeftMs] = createSignal(props.ms);
  const [intervalId, setIntervalId] = createSignal<IntervalType | null>(null);

  const progress = () => (timeLeftMs() / props.ms) * 100;

  const startCountdown = () => {
    const startTime = Date.now();
    const endTime = startTime + timeLeftMs();

    const newIntervalId = setInterval(() => {
      const now = Date.now();
      const remaining = Math.max(0, endTime - now);
      setTimeLeftMs(remaining);

      if (remaining <= 0) {
        if (newIntervalId) {
          clearInterval(newIntervalId);
        }
        props.closeFn();
      }
    }, 50);

    setIntervalId(newIntervalId);
  };

  const stopCountdown = () => {
    const currentIntervalId = intervalId();
    if (currentIntervalId) {
      clearInterval(currentIntervalId);
      setIntervalId(null);
    }
    setTimeLeftMs(0);
  };

  onMount(() => {
    startCountdown();
  });

  onCleanup(() => {
    const currentIntervalId = intervalId();
    if (currentIntervalId) {
      clearInterval(currentIntervalId);
    }
  });

  return (
    <div classList={{ [`${props.class}`]: !!props.class }}>
      <Show when={timeLeftMs()}>
        <div class="flex items-center">
          <div class="flex justify-end w-full h-2 bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-full bg-roma-blue transition-all duration-50 ease-linear"
              style={{ width: `${progress()}%` }}
            />
          </div>
          <button class="ml-2" onClick={stopCountdown}>
            <Icon path={pauseCircle} class="w-5" />
          </button>
        </div>
      </Show>
    </div>
  );
};

export const SignInModal: Component<SignInModalProps> = (props) => {
  const { setSession, session } = useSessionContext();
  const { addError, reportErrorToSentry } = useErrorContext();
  const login = useAction(loginAction);
  const submission = useSubmission(loginAction);
  const [, setParams] = useSearchParams<{ signIn?: string }>();
  const navigate = useNavigate();
  // ! TESTING
  const [cancelCountdown, setCancelCountdown] = createSignal(false);
  const [isSignedIn, setIsSignedIn] = createSignal(!!session()?.token);

  onMount(() => {
    setIsSignedIn(false);
  });

  onCleanup(() => {
    setIsSignedIn(false);
  });

  // ? workaround for input focus..test with Safari, some wierd scroll behaviour
  // ? previously when f-ing with focus.
  // createEffect(() => {
  //   if (props.open()) {
  //     const el = document.getElementById('usernameRef');
  //     if (el) {
  //       setTimeout(()=>{el.focus();}, 100)
  //     }
  //   }
  // });

  const nonEmpty = z
    .string()
    .trim()
    .min(1, { message: "Field cannot be empty" });

  const schema = z.object({
    username: nonEmpty,
    password: nonEmpty,
  });

  const { form, errors, isDirty, isSubmitting } = createForm<{
    username: string;
    password: string;
  }>({
    extend: validator({ schema }),
    onSubmit: async (values, { event }) => {
      event?.preventDefault();
      const response = await login(values.username, values.password);

      if ("Token" in response && response.Token !== "") {
        // added in fetch for permissions here as a stopgap. previously
        // ProfileQuickLinks would get/set permissions when it was rendered:
        // session token would trigger render, then get/set of permissions.
        // delay was causing issue on protected route where both isPartner
        // and permission checks where in place. Should permissions just come
        // back from sign-in repsonse?
        const data = await fetchAPI2<AccountStatus>("/account/status", {
          token: response.Token,
        });

        const formattedName = properCase(response.FirstName);

        await setSession({
          token: response.Token,
          name: formattedName,
          cart_id: response.CurrentCartID,
          last_login: new Date(),
          permissions: data.Permissions,
          currency: data.Currency,
          user_id: response.ID,
        });

        Sentry.setTag("romaSentry-user-id", response.ID);
        Sentry.setTag("romaSentry-user-locale", data.Currency);

        setIsSignedIn(true);
      } else if ("Reset" in response) {
        handleClose();
        queueMicrotask(() => {
          navigate(
            `/forgot-password?code=${response.Code}&email=${response.Email}`
          );
        });
      }
    },
    onError: (error) => {
      const err = checkError(error);
      if (err instanceof APIError && err.code === "INVALID_LOGIN") return;
      reportErrorToSentry(err);
    },
  });
  form;

  const handleClose = () => {
    setIsSignedIn(false);
    props.onClose();
  };

  const closeModalFns = {
    onFinalFocus: handleClose,
    onOutsidePointer: handleClose,
    onEscapeKeyDown: handleClose,
    onOutsideFocus: handleClose,
  };

  return (
    <>
      <Show when={props.open()}>
        <CDialog role="dialog" open={true} {...closeModalFns}>
          <CDialog.Portal>
            <CDialog.Overlay class="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm" />
            <CDialog.Content class="overflow-hidden fixed left-1/2 top-1/2 z-50 min-w-[320px] max-w-[480px] -translate-x-1/2 -translate-y-1/2 rounded-lg  bg-white px-6 py-5 duration-200 corvu-open:animate-in corvu-open:fade-in-0 corvu-open:zoom-in-95 corvu-open:slide-in-from-left-1/2 corvu-open:slide-in-from-top-[60%] corvu-closed:animate-out corvu-closed:fade-out-0 corvu-closed:zoom-out-95 corvu-closed:slide-out-to-left-1/2 corvu-closed:slide-out-to-top-[60%] transition-all">
              <div class=" flex justify-between items-start space-x-2 border-b pb-3">
                <CDialog.Label class="text-lg font-bold flex items-center">
                  <Presence exitBeforeEnter initial={false}>
                    <Switch>
                      <Match when={submission.error}>
                        <Motion.div
                          initial={{ opacity: 0, y: "50%" }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: "-50%" }}
                        >
                          <Icon
                            path={exclamationCircle}
                            class="w-7 text-red-600"
                          />
                        </Motion.div>
                      </Match>
                      <Match
                        when={!isSignedIn()}
                        // when={!mockSession()}
                      >
                        <Motion.div
                          initial={{ opacity: 0, y: "50%" }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: "-50%" }}
                        >
                          <Icon
                            path={user}
                            class={`w-7 ${
                              submission.pending || isSubmitting()
                                ? "animate-pulse"
                                : ""
                            } `}
                          />
                        </Motion.div>
                      </Match>
                      <Match
                        when={isSignedIn()}
                        // when={mockSession()}
                      >
                        <Motion.div
                          initial={{ opacity: 0, y: "50%" }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: "-50%" }}
                        >
                          <Icon path={checkCircle} class="w-7 text-green-700" />
                        </Motion.div>
                      </Match>
                    </Switch>
                  </Presence>
                  <div class="ml-2">Sign In</div>
                </CDialog.Label>
                <div>
                  <CDialog.Close
                    class="ml-auto p-1 text-roma-dark-grey hover:text-black"
                    as="button"
                    onClick={handleClose}
                  >
                    <Icon path={xMark} class="w-5 h-5" />
                    <span class="sr-only">Close alert window</span>
                  </CDialog.Close>
                </div>
              </div>
              <main
                class={`transition-[max-height] delay-300 duration-300 overflow-hidden px-1
              ${isSignedIn() ? "max-h-[350px]" : "max-h-[500px]"}`}
              >
                <p
                  class={`text-roma-dark-grey delay-300 duration-300 text-left mt-4 transition-all ${
                    !isSignedIn()
                      ? "opacity-100 max-h-10"
                      : "opacity-0 max-h-0 "
                  }`}
                >
                  To access ordering and pricing, please sign into your account.
                </p>

                <Show when={submission.error}>
                  <Motion.div
                    initial={{ opacity: 0, y: "50%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "-50%" }}
                    class="border-2 border-red-500 text-red-500 p-3 rounded-md mt-4"
                  >
                    {submission.error.message}
                  </Motion.div>
                </Show>

                <Presence exitBeforeEnter initial={false}>
                  <Show
                    // when={false}
                    when={!isSignedIn()}
                    // when={!mockSession()}
                  >
                    <Motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { delay: 0.3 } }}
                      exit={{ opacity: 0 }}
                      // transition={{ duration: 0.3 }}
                    >
                      <form use:form class="flex flex-col gap-4 mt-6">
                        <TextFieldInput
                          id="usernameRef"
                          name="username"
                          class="text-base"
                          label="Username/Email"
                          autocomplete="email"
                          autocapitalize="off"
                          autocorrect="off"
                          type="text"
                          required={true}
                          disabled={submission.pending}
                          error={errors().username}
                          validationState={
                            isDirty() && errors().username ? "invalid" : "valid"
                          }
                        />
                        <TextFieldInput
                          id="sign-in-password"
                          name="password"
                          class="text-base"
                          label="Password"
                          type="password"
                          required={true}
                          icon={eyeSlash}
                          disabled={submission.pending}
                          autocomplete="password"
                          autocapitalize="off"
                          autocorrect="off"
                          error={errors().password}
                          validationState={
                            isDirty() && errors().password ? "invalid" : "valid"
                          }
                        />
                        <div class="flex justify-end">
                          <button
                            type="button"
                            class="text-sm text-roma-blue font-light"
                            onClick={() => {
                              handleClose();
                              queueMicrotask(() => {
                                navigate("/forgot-password");
                              });
                            }}
                          >
                            Forgot Password?
                          </button>
                        </div>
                        <p class="text-sm text-roma-dark-grey text-center">
                          By logging in, you agree to Roma Moulding's{" "}
                          <button
                            type="button"
                            class="underline"
                            onClick={() => {
                              handleClose();
                              queueMicrotask(() => {
                                navigate("/privacy-policy");
                              });
                            }}
                          >
                            Privacy Policy
                          </button>
                          .
                        </p>
                        <Button
                          tabIndex="3"
                          class="flex gap-2 w-full text-base"
                          disabled={submission.pending || isSubmitting()}
                          type="submit"
                        >
                          <div class="w-full  flex items-center justify-center relative">
                            <span>Sign In</span>
                            <div class="absolute right-0">
                              <Loader
                                show={submission.pending || isSubmitting()}
                                class="!mr-0"
                                height="5"
                                width="5"
                              />
                            </div>
                          </div>
                        </Button>
                        <p class="text-sm font-medium text-center">
                          Not a partner?{" "}
                          <button
                            type="button"
                            class="text-roma-blue underline"
                            onClick={() => {
                              handleClose();
                              queueMicrotask(() => {
                                navigate("/support/partner");
                              });
                            }}
                          >
                            Join Us
                          </button>
                        </p>
                      </form>
                    </Motion.div>
                  </Show>
                  <Show
                    when={isSignedIn()}
                    // when={mockSession()}
                  >
                    <Motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { delay: 0.3 } }}
                      exit={{ opacity: 0 }}
                      // transition={{ duration: 0.3 }}
                      class=""
                    >
                      <div class="py-2 text-sm mb-3">
                        <div class="flex items-center pb-4  ">
                          <div class="-rotate-[20deg]">
                            <Icon
                              path={handRaised}
                              class="w-8 wave-animation"
                            />
                          </div>
                          <span class="ml-2 text-xl font-bold">
                            Welcome back
                            {session()?.name ? `, ${session()?.name}` : ""}!
                          </span>
                        </div>

                        <label for="quick-links mb-2" class="font-bold">
                          Quicklinks
                        </label>
                        <ul
                          id="quick-links"
                          class="list-disc list-inside text-roma-blue pb-2"
                        >
                          <li>
                            <button
                              onClick={() => {
                                setCancelCountdown(true);
                                navigate("/order?signIn=true");
                              }}
                            >
                              Place an order
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setCancelCountdown(true);
                                navigate("/shop?signIn=true");
                              }}
                            >
                              Browse our collection
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setCancelCountdown(true);
                                navigate("/checkout/current/?signIn=true");
                              }}
                            >
                              View your cart
                            </button>
                          </li>
                        </ul>

                        {/* TESTING - WEB UPDATES FROM API? */}
                        {/* <label for="Updates" class="font-bold">
                          Updates - Version 1.9.22
                        </label>
                        <ul id="updates" class="list-disc list-inside pb-2">
                          <li>Express Order improvements</li>
                          <li>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Dolores dolore asperiores facilis modi
                            pariatur harum veritatis? Minus error omnis
                            repudiandae?
                          </li>
                        </ul> */}
                      </div>
                      <Show when={!cancelCountdown()}>
                        <CloseComp
                          closeFn={() => {
                            setParams({ signIn: undefined });
                          }}
                          ms={5000}
                        />
                      </Show>
                    </Motion.div>
                  </Show>
                </Presence>
              </main>
            </CDialog.Content>
          </CDialog.Portal>
        </CDialog>
      </Show>
    </>
  );
};
