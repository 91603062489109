import {
  For,
  Show,
  createSignal,
  type Component,
  createMemo,
  Suspense,
  Accessor,
  ErrorBoundary,
  type JSX,
} from "solid-js";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import {
  AccessorWithLatest,
  useAction,
  createAsync,
  useNavigate,
  A,
  useSubmission,
  useLocation,
} from "@solidjs/router";
import { SelectBox, TextFieldInput } from "~/components/inputs";
import {
  updateCurrentCartAction,
  createCartAction,
  retrieveCart,
  addToCartAction,
  deleteCartAction,
  clearCartAction,
  removeFromCartAction,
  useListCarts,
} from "~/services/cart";
import { CartDetail } from "~/services/roma-api/cart/types";
import { BaseLoader, BaseSkeleton } from "~/components/utility";
import { Motion, Presence } from "solid-motionone";
import Button from "~/components/Button";
import { numberWithCommas } from "~/utils/helpers";
import { usePrompt } from "~/components/utility";
import { LineImage, LineAttributes } from "~/components/ordering/checkout";
import { LineItem } from "~/services/roma-api/cart/types-lineItems";
import { Icon } from "solid-heroicons";
import {
  ellipsisVertical,
  pencilSquare,
  trash,
  square_2Stack,
  truck,
  check,
  xMark,
} from "solid-heroicons/outline";
import { PanelItemMenu, type PanelItemMenuGroup } from "./";
import { convertLineItemToOrderItem } from "./cart-panel/utils";
import { PT } from "~/utils/products";
import { PanelLoadingBlocker, PanelItemsSkeleton } from "./";
import { ModeSwitcher } from "./ModeSwitcher";
import { checkError } from "~/services/roma-api/checkError";
import { APIError } from "~/services/roma-api/errors";
import { ProductTypeIcon } from "~/components/product";

type CartPanelProps = {
  currentCart?: AccessorWithLatest<CartDetail | undefined>; // call here or in SidePanel or both?
};

// TODO - add in permission blocker

export const CartPanel: Component<CartPanelProps> = () => {
  const { session, isPartner, aCartActionIsPending, permission, clearSession } =
    useSessionContext();
  const { panel, setPanel } = useSiteContext();
  const { addError, reportErrorToSentry } = useErrorContext();
  const [showCartOptions, setShowCartOptions] = createSignal<
    "switch" | "add" | undefined
  >();
  const navigate = useNavigate();
  const [Prompt, openPrompt] = usePrompt();
  const [panelError, setPanelError] = createSignal<string>();
  const location = useLocation();
  const isCurrentlyOnCheckoutPage = createMemo(() => {
    return location.pathname.includes("checkout");
  });

  const cart = createAsync(async () => {
    try {
      return await retrieveCart(session()?.cart_id);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[CartPanel.tsx]: Error caught in createAsync callback: ",
          err
        );
      }
      throw err;
    }
  });

  // * ACTIONS * //

  const updateCart = useAction(updateCurrentCartAction);
  const createCart = useAction(createCartAction);
  const addToCart = useAction(addToCartAction);
  const deleteCart = useAction(deleteCartAction);
  const clearCart = useAction(clearCartAction);
  const removeFromCart = useAction(removeFromCartAction);
  const creatingCart = useSubmission(createCartAction);

  // * FUNCTIONS * //

  // if the cart panel is open, display locally, otherwise dispatch to error notification bar.
  const dealWithError = (
    // HERE
    error: Error | APIError | unknown,
    details: { title?: string; message: string }
  ) => {
    // TODO - put Sentry logic here?
    const err = checkError(error);
    const titleAndMessage = { title: "Cart Error", ...details };

    if (panel().open) {
      setPanelError(details.message);

      // !HERE -
      // TODO - test/add feedback, id, etc next

      const sentryEventId = reportErrorToSentry(err);

      // const { sentryEventId, notificationId } = addError(err, {
      //   ...titleAndMessage,
      //   // suppressSentryReport: true,
      //   severity: "warning",
      //   showDetails: true,
      //   actions: [{
      //     label: "Contact Support",
      //     action: async () => {
      //       navigate("/support");
      //     },
      //   },]
      // });

      // console.log("EVENT ID AFTER ADDERROR() >>>", sentryEventId);
    } else {
      const { sentryEventId, notificationId } = addError(err, {
        ...titleAndMessage,
        severity: "warning",
        showDetails: true,
      });
    }
  };

  const closePanel = () => {
    setPanel({ ...panel(), open: false });
  };

  const handleUpdateCart = async (id: string) => {
    try {
      await updateCart(id);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.log("handleUpdateCart catching error >> ", error);
      }
      dealWithError(error, {
        title: "Cart Error",
        message:
          "An error occurred while switching your current cart. Please try again or contact customer support.",
      });

      // TODO - sentry?
    }
  };

  const handleDeleteCart = async () => {
    const confirmed = await openPrompt({
      title: "Confirm Cart Deletion",
      description:
        "Are you sure you want to delete this cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Delete Cart", value: true, style: "red" },
      ],
    });

    if (confirmed) {
      try {
        await deleteCart();
      } catch (error) {
        if (import.meta.env.DEV) {
          console.log("handleDeleteCart caught error: ", error);
        }
        dealWithError(error, {
          message:
            "An error occurred while deleting this cart. Please try again or contact customer support.",
        });
        // TODO - Sentry?
      }
    }
    return;
  };

  const handleDeleteCartLine = async (id: string) => {
    const confirmed = await openPrompt({
      title: "Confirm Item Deletion",
      description:
        "Are you sure you want to delete this item from your cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Delete Item", value: true, style: "red" },
      ],
    });

    if (confirmed) {
      try {
        await removeFromCart(id);
      } catch (error) {
        if (import.meta.env.DEV) {
          console.log("handleDeleteCartLine catching error >> ", error);
        }
        const err = checkError(error);
        dealWithError(err, {
          message:
            "An error occurred while deleting this item. Please try again or contact customer support.",
        });
      }
    }
    return;
  };

  const handleEmpty = async () => {
    const confirmed = await openPrompt({
      title: "Empty Current Cart",
      description:
        "Are you sure you want to remove all items from this cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Empty Cart", value: true, style: "red" },
      ],
    });

    if (confirmed) {
      try {
        await clearCart();
      } catch (error) {
        if (import.meta.env.DEV) {
          console.log("[handleEmpty] caught error: ", error);
        }
        dealWithError(error, {
          message:
            "An error occurred while emptying this cart. Please try again or contact customer support",
        });
        // TODO - Sentry?
      }
    }
    return;
  };

  const handleDuplicate = async (line: LineItem) => {
    const strippedLineItem = convertLineItemToOrderItem(line);

    try {
      await addToCart(strippedLineItem);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.log("handleDuplicate caught error: ", error);
      }

      const err = checkError(error);
      dealWithError(err, {
        message:
          "An error occurred while duplicating this item. Please try again or contact customer support.",
      });
      // TODO Sentry?
    }
  };

  const handleSignOut = async () => {
    await clearSession();
    setPanel({ open: false, mode: "cart" });
  };

  const CartOptionButtons: Component = () => {
    return (
      <ModeSwitcher
        mode={showCartOptions as Accessor<"add" | "switch">}
        containerClass="!border-transparent"
        buttons={[
          {
            label: "Switch Cart",
            matchMode: "switch",
            onClick: () => setShowCartOptions("switch"),
          },
          {
            label: "Add Cart",
            matchMode: "add",
            onClick: () => setShowCartOptions("add"),
          },
        ]}
      />
    );
  };

  const SwitchCart: Component = () => {
    const carts = useListCarts();

    const cartOptions = createMemo(() => {
      if (!carts()?.Results) return [];
      return carts()?.Results.map((cart) => ({
        label: `${cart.Name} ${
          import.meta.env.DEV ? `[${cart.LineCount}] (${cart.ID})` : ""
        }`,
        value: cart.ID,
      }));
    });

    return (
      <Show when={cartOptions() && cartOptions()!.length > 0}>
        <SelectBox
          triggerClass="bg-white"
          valueClass="truncate"
          options={cartOptions()!}
          onChange={async (option) => {
            // updateCart(option.value as string);
            await handleUpdateCart(option.value as string);
          }}
          value={{ value: session()?.cart_id }}
          loading={aCartActionIsPending()}
          disabled={aCartActionIsPending()}
        />
      </Show>
    );
  };

  const CreateNewCartForm: Component = () => {
    return (
      <>
        <form
          class="flex  space-x-2"
          onSubmit={async (e: SubmitEvent) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement;
            const formData = new FormData(form);
            const cartName = formData.get("cart-name");
            try {
              await createCart(cartName as string);
              setShowCartOptions(undefined);
            } catch (error) {
              if (import.meta.env.DEV) {
                console.log("[CreateNewCartForm]: catching error >> ", error);
              }
              dealWithError(error, {
                message: `An error occurred while creating a cart '${cartName}'. Please try again or contact customer support.`,
              });
              // TODO - Sentry
              // not really a critical error..? just mark and dispatch to sentry?
              // throw error;
            }
          }}
        >
          <TextFieldInput
            disabled={aCartActionIsPending()}
            type="text"
            name="cart-name"
            rootClass="grow"
            maxLength={30}
          />
          <button
            disabled={aCartActionIsPending()}
            class="bg-roma-blue text-white rounded-md px-2 disabled:bg-roma-medium-grey disabled:cursor-not-allowed flex items-center justify-center"
          >
            {aCartActionIsPending() ? (
              <BaseLoader width={5} height={5} class="!mr-0" />
            ) : (
              <span>Create Cart</span>
            )}
          </button>
        </form>
        {/* Using panelError() for now - decide whther to rely on useSubmission(action).error pattern*/}
        {/* <Show when={creatingCart.error}>
          <Motion.div
            class="text-red-500 text-xs flex items-center pt-2"
            initial={{ opacity: 0, y: "-20%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-20%" }}
          >
            <button onClick={() => creatingCart.clear()}>
              <Icon path={xMark} class="w-4 h-4 mr-1" stroke-width={3} />
            </button>
            <p class="mb-0.5">
              Something went wrong creating your cart. Please try again.
            </p>
          </Motion.div>
        </Show> */}
      </>
    );
  };

  const CartPanelLine: Component<{ line: LineItem }> = (props) => {
    const list: PanelItemMenuGroup = {
      list: [
        {
          label: "Edit",
          icon: pencilSquare,
          href: `/order?editCart=${props.line.ID}`,
        },
        {
          label: "Delete",
          icon: trash,
          onSelect: async () => {
            await handleDeleteCartLine(props.line.ID);
          },
        },
        {
          label: "Duplicate",
          icon: square_2Stack,
          // beta: true,
          disabled: props.line.Type === PT.CORNERSAMPLE,
          onSelect: async () => {
            await handleDuplicate(props.line);
          },
        },
        // {
        //   label: "Send to another cart",
        //   icon: arrowRightOnRectangle,
        //   disabled: true,
        //   onSelect: async () => {
        //     // removeFromCart(props.line.ID);
        //   },
        // },
      ],
    };

    const facilityMapAbbr: Record<string, string> = {
      BP01: "CA",
      BP03: "LA",
      BP04: "NJ",
      BP05: "AT",
    };

    return (
      <li class="flex py-1.5 group/line relative items-stretch">
        <div class="shrink-0 border border-transparent group-hover/line:border-gray-200 self-center relative ">
          <LineImage line={props.line} size="sm" />
          <div class="absolute bg-white border border-gray-300 overflow-hidden aspect-square bottom-1 right-1">
            <ProductTypeIcon
              productType={props.line.Type}
              size={20}
              enableTooltip
            />
          </div>
        </div>
        <div class="ml-1.5 grow group-hover/line:bg-roma-grey px-1 py-0.5 flex flex-col">
          <LineAttributes
            line={props.line}
            rootClass="!ml-0 !h-auto"
            textSize="xs"
            hideShipAttr
          />
          <div class="text-xs mt-auto flex items-center">
            <Show when={props.line.Amount !== 0}>
              <p class="text-sm font-bold">
                ${numberWithCommas(props.line.Amount.toFixed(2))}
              </p>
            </Show>

            <Show when={Math.abs(props.line.Discount) > 0}>
              <p class="ml-3 text-xs text-gray-400 line-through">
                $
                {numberWithCommas(
                  (props.line.Amount + Math.abs(props.line.Discount)).toFixed(2)
                )}
              </p>
            </Show>
          </div>
        </div>
        {/* ICON ATTRIBUteS */}
        <div class="group-hover/line:bg-roma-grey p-1 flex flex-col gap-0.5 text-[10px] text-roma-dark-grey">
          <Show when={props.line.Plant && props.line.Plant !== ""}>
            <div
              title={`Ships from ${facilityMapAbbr[props.line.Plant]}`}
              class="border rounded-md flex items-center   py-0.5 px-1 bg-roma-grey  group-hover/line:bg-white"
            >
              <span>{facilityMapAbbr[props.line.Plant]}</span>
              <Icon path={truck} class=" ml-0.5 w-4 h-4" />
            </div>
          </Show>

          <Show when={props.line.FreightAccepted}>
            <div
              title="Freight Accepted"
              class="border rounded-md flex items-center   py-0.5 px-1 bg-roma-grey  group-hover/line:bg-white"
            >
              <Icon path={check} class="w-3 h-3" stroke-width={"2.5"} />
              <AkarIconsShippingBox01
                class="ml-0.5"
                width="16px"
                height="16px"
              />
            </div>
          </Show>
          <Show when={props.line.BackOrder}>
            <div
              title="Place on Backorder"
              class="border rounded-md flex items-center   py-0.5 px-1 bg-roma-grey  group-hover/line:bg-white"
            >
              <Icon path={check} class="w-3 h-3 mr-0.5" stroke-width={"2.5"} />
              <span>BO</span>
            </div>
          </Show>
          <Show when={props.line.CancelBackOrder}>
            <div
              title="Cancel on Backorder"
              class="border rounded-md flex items-center   py-0.5 px-1 bg-roma-grey  group-hover/line:bg-white"
            >
              <Icon path={xMark} class="w-3 h-3 mr-0.5" stroke-width={"2.5"} />
              <span>BO</span>
            </div>
          </Show>
        </div>
        <PanelItemMenu
          placement="left"
          gutter={0}
          list={list}
          triggerClass="text-roma-medium-grey hover:text-black transition-colors"
        >
          <Icon path={ellipsisVertical} class="w-5 h-5" />
        </PanelItemMenu>
      </li>
    );
  };

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => {
          const error = checkError(err);
          addError(error, {
            severity: "critical",
            title: "Cart Error",
            message:
              "Error retrieving cart details. You may experience issues adding to - or checking out your current cart. If this error persists, please contact customer support",
            suggestions: [
              "Please try signing out and signing back in.",
              "Try creating a new cart",
              "If this error persists, please contact support.",
            ],
            actions: [
              {
                label: "Sign Out",
                action: async () => {
                  setPanel({ mode: "fav", open: false });
                  await clearSession();
                },
                dismissAfter: true,
              },
              {
                label: "Contact Support",
                action: async () => {
                  navigate("/support");
                },
              },
            ],
            showDetails: true,
          });
          return (
            <div class="bg-red-100 my-4 rounded-md flex-col gap-2  p-4 text-sm">
              <p class="font-medium text-base mb-4">
                An error occurred while retrieving your cart details.
              </p>
              <p class="mb-2 font-medium">Suggested Actions:</p>

              <ul class="list-inside list-disc flex flex-col gap-1 mb-2">
                <li>
                  <button onClick={handleSignOut} class="text-roma-blue">
                    Sign out of your account
                  </button>
                </li>
                <li>
                  <button class="text-roma-blue" onClick={reset}>
                    Reset
                  </button>
                </li>
              </ul>

              <div class="border-t border-roma-medium-grey text-roma-medium-grey mt-2 py-2">
                <p class="font-medium">Details:</p>
                <p>Status: {err.statusCode}</p>
                <p>Code: {err.code}</p>
                <p>Message: {err.message}</p>
              </div>
            </div>
          );
        }}
      >
        <Suspense fallback={<PanelItemsSkeleton />}>
          <>
            <div class="py-2 border-b text-sm relative">
              <div class="absolute inset-x-0  z-[999] ">
                <Show when={panelError()}>
                  <Motion.div
                    class="bg-red-500 min-h-12 text-xs text-white flex flex-col p-2 rounded-md z-[199] -my-1 shadow-md"
                    initial={{ opacity: 0, transform: "translateY(100%)" }}
                    animate={{ opacity: 1, transform: "translateY(0)" }}
                    exit={{ opacity: 0, transform: "translateY(100%)" }}
                  >
                    <div class="flex ">
                      <p class="grow">{panelError()}</p>
                      <button
                        type="button"
                        class="self-start shrink-0"
                        onClick={() => {
                          setPanelError(undefined);
                        }}
                      >
                        <Icon path={xMark} class="ml-1 w-4 h-4" />
                      </button>
                    </div>

                    {/* <details class="mt-1">
                      <summary>Details</summary>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Esse corporis provident natus cum voluptas commodi ut,
                      accusamus excepturi iste libero sapiente ipsa quasi neque,
                      modi placeat culpa, expedita veniam temporibus laborum
                      blanditiis sit vero obcaecati! Ut atque nemo corporis
                      cumque?
                    </details> */}
                  </Motion.div>
                </Show>
              </div>
              <div class="flex justify-between space-x-2 items-start">
                <Show
                  when={showCartOptions()}
                  fallback={
                    <>
                      <div class="truncate">
                        <p class="truncate font-medium text-base">
                          {cart()?.Name}
                        </p>
                        <p class="font-light">
                          {cart()?.LineCount} Item
                          {cart()?.LineCount! != 1 ? "s" : ""}
                        </p>
                      </div>
                    </>
                  }
                >
                  <CartOptionButtons />
                </Show>

                <button
                  onClick={() =>
                    showCartOptions()
                      ? setShowCartOptions(undefined)
                      : setShowCartOptions("switch")
                  }
                  disabled={aCartActionIsPending()}
                  class="text-roma-blue shrink-0 mt-1.5 flex items-center active:translate-y-0.5"
                >
                  <span> {showCartOptions() ? "Hide" : "Switch/Add Cart"}</span>
                </button>
              </div>

              <Show when={showCartOptions()} keyed>
                {(option) => (
                  <Motion.div
                    initial={{
                      opacity: 0,
                      x: option === "add" ? "50%" : "-50%",
                    }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: option === "add" ? "50%" : "-50%" }}
                    class="py-2 space-y-1  mx-1"
                  >
                    {/* <Show when={cartOptions() && option === "switch"}> */}
                    <Show when={option === "switch"}>
                      <ErrorBoundary
                        fallback={(err, reset) => {
                          // TODO Sentry..
                          return (
                            <div class="bg-red-100 rounded-md p-2 flex items-center">
                              <p class="text-red-500 text-sm">
                                Error retrieving cart list
                              </p>
                              <button
                                class="text-xs pl-1 ml-auto"
                                type="button"
                                onClick={reset}
                              >
                                Reset/Retry
                              </button>
                            </div>
                          );
                        }}
                      >
                        <Suspense fallback={<BaseSkeleton height={38} />}>
                          <SwitchCart />
                        </Suspense>
                      </ErrorBoundary>
                    </Show>
                    <Show when={option === "add"}>
                      <CreateNewCartForm />
                    </Show>
                  </Motion.div>
                )}
              </Show>
            </div>

            <Suspense fallback={<PanelItemsSkeleton />}>
              <Show when={aCartActionIsPending()}>
                <PanelLoadingBlocker />
              </Show>
              <ul class="grow divide-y overflow-y-auto ">
                <Show
                  when={cart()?.Lines?.length! > 0}
                  fallback={
                    <div class="flex flex-col items-center justify-center h-full">
                      <div>
                        <p class="text-lg">This cart is empty</p>
                        <ul class="text-sm mt-4 list-disc list-inside">
                          <li>
                            Browse mouldings in the{" "}
                            <A href="/shop" class="text-roma-blue">
                              Shop
                            </A>
                          </li>
                          <li>
                            Build an{" "}
                            <A href="/order" class="text-roma-blue">
                              Order
                            </A>
                          </li>
                          <li>
                            <button
                              class="text-roma-blue"
                              onClick={() => setShowCartOptions("switch")}
                            >
                              Switch
                            </button>{" "}
                            to another cart
                          </li>
                        </ul>
                      </div>
                    </div>
                  }
                >
                  <For each={cart()?.Lines}>
                    {(line) => <CartPanelLine line={line} />}
                  </For>
                </Show>
              </ul>
              <div class="border-t-2 border-gray-200 h-auto  text-sm shrink-0 mt-auto py-2 space-y-2 flex flex-col items-center">
                <div class="flex justify-between text-lg font-bold w-full px-4 ">
                  <p>Total</p>
                  <p>${numberWithCommas(cart()?.Amount.toFixed(2))}</p>
                </div>
                <div class="w-full flex justify-between space-x-2 pb-2">
                  <Button
                    style="outline"
                    disableHover
                    onClick={() => {
                      navigate(`/order`);
                      closePanel();
                    }}
                  >
                    <span class="px-2 text-xs sm:text-sm">
                      Continue Ordering
                    </span>
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(`/checkout/${session()?.cart_id}`, {
                        state: isCurrentlyOnCheckoutPage()
                          ? "revalidate"
                          : undefined,
                      });
                      closePanel();
                    }}
                    rootClass="grow"
                    class="w-full"
                    disableHover
                    disabled={aCartActionIsPending()}
                  >
                    <span class="px-2 text-xs sm:text-sm">
                      View Cart & Checkout
                    </span>
                  </Button>
                </div>
                <div class="border-t w-full py-1 flex space-x-4 justify-between text-red-600 text-xs">
                  <button onClick={handleEmpty}>Empty Cart</button>
                  <button onClick={handleDeleteCart}>Delete Cart</button>
                  {Prompt}
                </div>
              </div>
            </Suspense>
          </>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export function AkarIconsTruck(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      >
        <path d="M10 17h6V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a1 1 0 0 0 1 1h1m18-1v-4a4 4 0 0 0-4-4h-2v9h5a1 1 0 0 0 1-1m-7 1a3 3 0 1 0 6 0zm7-3h-2" />
        <circle cx="7" cy="17" r="3" />
      </g>
    </svg>
  );
}

export function AkarIconsShippingBox01(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="1"
      >
        <path
          stroke-linecap="round"
          d="M11.029 2.54a2 2 0 0 1 1.942 0l7.515 4.174a1 1 0 0 1 .514.874v8.235a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 3 15.824V7.588a1 1 0 0 1 .514-.874z"
        />
        <path d="m3 7l9 5m0 0l9-5m-9 5v10" />
        <path stroke-linecap="round" d="m7.5 9.5l9-5M6 12.328L9 14" />
      </g>
    </svg>
  );
}
