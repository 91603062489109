import {
  type VoidComponent,
  createSignal,
  Show,
  ErrorBoundary,
  Suspense,
  type Component,
} from "solid-js";
import { Icon } from "solid-heroicons";
import {
  xMark,
  heart,
  shoppingCart,
  exclamationCircle,
} from "solid-heroicons/outline";
import { heart as heartSolid } from "solid-heroicons/solid";
import Drawer, { Size } from "@corvu/drawer";
import { CartPanel } from "./CartPanel";
import { FavouritesPanel } from "./FavouritesPanel";
import { useListCarts } from "~/services/cart";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import { createAsync, useNavigate, useSubmission } from "@solidjs/router";
import { retrieveCart } from "~/services/cart";
import {
  addFavouriteAction,
  deleteFavouriteAction,
  getFavourites,
} from "~/services/favourites";
import { Motion, Presence } from "solid-motionone";
import { BaseLoader } from "~/components/utility";
import { usePrompt } from "~/components/utility";
import { ModeSwitcher } from "./ModeSwitcher";
import { checkError } from "~/services/roma-api/errors";

export const SidePanel: VoidComponent = () => {
  const { isPartner, session, aCartActionIsPending, clearSession } =
    useSessionContext();
  const { addError } = useErrorContext();
  const { panel, setPanel } = useSiteContext();
  const [width, setWidth] = createSignal(400);
  const navigate = useNavigate();
  // const [Prompt, openPrompt] = usePrompt();

  const CartTrigger: Component = () => {
    const cart = createAsync(async () => {
      if (!isPartner()) return;

      try {
        return await retrieveCart(session()?.cart_id);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[SidePanel.tsx]: Error caught in createAsync: ", err);
        }
        throw err;
      }
    });

    return (
      <Drawer.Trigger
        as="button"
        aria-label="Shopping Cart"
        onClick={() => {
          setPanel({ open: true, mode: "cart" });
        }}
        class="relative hover:text-roma-blue transition-all duration-100  active:translate-y-0.5 whitespace-nowrap"
      >
        <Show
          when={!aCartActionIsPending()}
          fallback={<BaseLoader width={5} class="!mr-0" />}
        >
          <Icon stroke-width="1.75" path={shoppingCart} class="w-5 h-5" />
        </Show>
        <Show when={cart()?.LineCount} keyed>
          {(lineCount) => (
            <div
              aria-hidden
              class="absolute top-3 -right-2 text-[9px] flex items-center justify-center bg-roma-blue text-white rounded-full  w-4 h-4"
            >
              <span>{lineCount > 9 ? "9+" : lineCount}</span>
            </div>
          )}
        </Show>
      </Drawer.Trigger>
    );
  };

  const addingFav = useSubmission(addFavouriteAction);
  const deletingFav = useSubmission(deleteFavouriteAction);
  const favActionIsPending = () => addingFav.pending || deletingFav.pending;

  const closeDrawer = () => {
    setPanel({ ...panel(), open: false });
  };

  const manualCloseDrawerFns = {
    onEscapeKeyDown() {
      closeDrawer();
    },
    onOutsidePointer() {
      closeDrawer();
    },
    onOutsideFocus() {
      closeDrawer();
    },
    onActiveSnapPointChange(snapPoint: Size) {
      if (snapPoint == 0) {
        closeDrawer();
      }
    },
  };

  return (
    <>
      {/* <ErrorBoundary
        fallback={<div class="border border-red-500 bg-red-100">ERROR</div>}
      > */}
      <Drawer side="right" open={panel().open} {...manualCloseDrawerFns}>
        {(props) => (
          <>
            <Show when={isPartner()}>
              <div class="flex ml-4 space-x-4">
                <Drawer.Trigger
                  as="button"
                  aria-label="Favourites"
                  onClick={() => {
                    // setMode("fav");
                    setPanel({ open: true, mode: "fav" });
                  }}
                  class="relative hover:text-roma-blue transition-all duration-100  active:translate-y-0.5 whitespace-nowrap"
                >
                  <Icon
                    stroke-width="1.75"
                    path={favActionIsPending() ? heartSolid : heart}
                    class={`w-5 h-5 ${
                      favActionIsPending() ? "text-roma-blue animate-pulse" : ""
                    }`}
                  />
                </Drawer.Trigger>
                <ErrorBoundary
                  fallback={(error, reset) => {
                    const err = checkError(error);
                    addError(err, {
                      severity: "critical",
                      title: "Cart Error",
                      message:
                        "Error retrieving cart details. You may experience issues adding to - or checking out your current cart. If this error persists, please contact customer support",
                      suggestions: [
                        "Please try signing out and signing back in.",
                        "Try creating a new cart",
                        "If this error persists, please contact support.",
                      ],
                      actions: [
                        {
                          label: "Sign Out",
                          action: async () => {
                            setPanel({ mode: "fav", open: false });
                            await clearSession();
                          },
                          dismissAfter: true,
                        },
                        {
                          label: "Contact Support",
                          action: async () => {
                            navigate("/support");
                          },
                        },
                      ],
                      showDetails: true,
                    });
                    return (
                      <button
                        class="flex items-center relative"
                        onClick={() => {
                          setPanel({ mode: "cart", open: true });
                        }}
                      >
                        <Icon
                          stroke-width="1.75"
                          path={shoppingCart}
                          class="w-5 h-5"
                        />
                        <Icon
                          stroke-width="1.75"
                          path={exclamationCircle}
                          class="w-4 h-4 text-red-600 absolute top-4 -right-1.5 bg-white rounded-full"
                        />
                      </button>
                    );
                  }}
                >
                  <CartTrigger />
                  {/* <Drawer.Trigger
                    as="button"
                    aria-label="Shopping Cart"
                    onClick={() => {
                      setPanel({ open: true, mode: "cart" });
                    }}
                    class="relative hover:text-roma-blue transition-all duration-100  active:translate-y-0.5 whitespace-nowrap"
                  >
                    <Show
                      when={!aCartActionIsPending()}
                      fallback={<BaseLoader width={5} class="!mr-0" />}
                    >
                      <Icon
                        stroke-width="1.75"
                        path={shoppingCart}
                        class="w-5 h-5"
                      />
                    </Show>
                    <Show when={cart()?.LineCount} keyed>
                      {(lineCount) => (
                        <div
                          aria-hidden
                          class="absolute top-3 -right-2 text-[8px] flex items-center justify-center bg-roma-blue text-white rounded-full  w-4 h-4"
                        >
                          <span>{lineCount > 9 ? "9+" : lineCount}</span>
                        </div>
                      )}
                    </Show>
                  </Drawer.Trigger> */}
                </ErrorBoundary>
              </div>
            </Show>

            <Drawer.Portal>
              <Drawer.Overlay
                class="fixed inset-0 z-50 corvu-transitioning:transition-colors corvu-transitioning:duration-500 corvu-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)]"
                style={{
                  "background-color": `rgb(0 0 0 / ${
                    0.3 * props.openPercentage
                  })`,
                  "backdrop-filter": `blur(${3 * props.openPercentage}px)`,
                }}
              />

              <Drawer.Content class="group flex fixed w-[22rem] sm:w-[28rem] inset-y-0 right-0 z-[50]   rounded-l-lg border-l-2 border-gray-200 bg-white pl-1 pr-4 after:absolute after:inset-x-0 after:top-[calc(100%-1px)] after:h-1/2 after:bg-inherit corvu-transitioning:transition-[transform,width] corvu-transitioning:duration-500 corvu-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)] md:select-none max-h-screen">
                <div class="h-10 w-1 self-center rounded-full bg-gray-200 mr-2 " />
                <div
                  class="w-full flex flex-col corvu-group-transitioning:transition-[width] corvu-group-transitioning:duration-500 corvu-group-transitioning:ease-[cubic-bezier(0.32,0.72,0,1)] pt-2 overflow-hidden max-h-fit h-full"
                  // style={{
                  //   height: `${
                  //     props.openPercentage < 0.7 ? 70 : props.openPercentage * 100
                  //   }%`,
                  // }}
                >
                  <div class="text-lg">
                    <ModeSwitcher
                      mode={() => panel().mode}
                      buttons={[
                        {
                          label: "Favorites",
                          matchMode: "fav",
                          onClick: () => setPanel({ mode: "fav", open: true }),
                        },
                        {
                          label: "Shopping Cart",
                          matchMode: "cart",
                          onClick: () => setPanel({ mode: "cart", open: true }),
                        },
                      ]}
                      besideButtons={
                        <Drawer.Close
                          onClick={closeDrawer}
                          class="text-roma-medium-grey hover:text-black ml-auto"
                        >
                          <Icon path={xMark} class="w-5 h-5" />
                        </Drawer.Close>
                      }
                    />
                  </div>

                  {/*  PANEL CONTENT */}

                  <ErrorBoundary
                    fallback={(err, reset) => {
                      // TODO Error-Boundary
                      return (
                        <div>
                          <p>An error occurred</p>
                        </div>
                      );
                    }}
                  >
                    <Suspense>
                      <Presence exitBeforeEnter initial={false}>
                        <Suspense>
                          <Show when={panel().mode === "cart"}>
                            <Motion.div
                              class="h-full flex flex-col overflow-hidden w-full"
                              initial={{ x: "100%", opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              exit={{
                                opacity: 0,
                                transition: { duration: 0.1 },
                              }}
                            >
                              <CartPanel />
                            </Motion.div>
                          </Show>
                        </Suspense>

                        <Suspense>
                          <Show when={panel().mode === "fav"}>
                            <Motion.div
                              class="h-full flex flex-col overflow-hidden w-full"
                              initial={{ x: "-100%", opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              exit={{
                                opacity: 0,
                                transition: { duration: 0.1 },
                              }}
                            >
                              <FavouritesPanel />
                            </Motion.div>
                          </Show>
                        </Suspense>
                      </Presence>
                    </Suspense>
                  </ErrorBoundary>
                </div>
              </Drawer.Content>
              {/* !END STICKY FOOTER */}
            </Drawer.Portal>
          </>
        )}
      </Drawer>
      {/* </ErrorBoundary> */}
    </>
  );
};
